import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useRef, useState } from "react";
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
// Updated import to our new streaming function
import {
  doChatStreaming,
  //getChatHistory,
  getChatHistoryStreaming,
  clearChatHistory
} from "../services/chat.service";

import { Loader, User, Bot, Trash2 } from "lucide-react";
import TextToHTML from "src/components/text-to-html";
import { EventSourceMessage } from "@microsoft/fetch-event-source"; // Type for the SSE events

// ActionButton Component
const ActionButton: React.FC<{
  title: string;
  subtitle: string;
  handleAction: (title: string) => void;
}> = ({ title, subtitle, handleAction }) => (
  <button
    onClick={() => handleAction(title)}
    className="bg-gray-800 text-white p-4 rounded-lg flex flex-col items-start justify-center w-full h-32 transition-colors hover:bg-gray-700"
  >
    <span className="text-lg font-semibold">{title}</span>
    <span className="text-sm text-gray-400">{subtitle}</span>
  </button>
);


function decodeBase64(base64String: string): string {
  try {
    // `atob` decodes standard Base64 in browsers
    return atob(base64String);
  } catch (error) {
    console.error("Error decoding base64:", error);
    return "Error decoding message";
  }
}


// Parent Component
export const ChatPage: React.FC = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingChatResponse, setIsLoadingChatResponse] = useState(false);
  const [strongifierInput, setStrongifierInput] = useState("");
  const [chatHistory, setChatHistory] = useState<string[][] | null>(null);

  // Store the AbortController for the SSE stream
  const streamingControllerRef = useRef<AbortController | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  /****************************************************************
   * 1) REPLACE THE OLD loadChatHistory function with SSE version *
   ****************************************************************/
  const loadChatHistory = async () => {
    // Show loading indicator
    setIsLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();

      // If there's an existing SSE in flight, abort it
      if (streamingControllerRef.current) {
        streamingControllerRef.current.abort();
      }

      // Start the SSE-based call
      const controller = getChatHistoryStreaming(
        accessToken,
        handleHistoryMessage,
        handleHistoryError,
        handleHistoryClose
      );

      // Keep a reference so we can abort on unmount
      streamingControllerRef.current = controller;
    } catch (error) {
      setMessage(
        `Error: ${
          error instanceof Error ? error.message : "Unknown error occurred"
        }`
      );
      setIsLoading(false);
    }
  };

  /****************************************************************************************
   * 2) SSE event handlers for partial updates (event = "update") and final chat (event = "final")
   ****************************************************************************************/
  const handleHistoryMessage = (evt: EventSourceMessage) => {
    const { event: evtName, data } = evt;
    const decodedData = decodeBase64(data);
  
    // If the server sends partial updates with `event: "update"`,
    // treat them as coming from "AI" in real-time
    if (evtName === "update") {
      setChatHistory(prev =>
        prev ? [...prev, ["AI", decodedData]] : [["AI", decodedData]]
      );
    }
    // If the server sends a final SSE chunk with the entire history
    else if (evtName === "chat_history") {
      try {
        // The final data is a JSON array of { role, content }
        const arr = JSON.parse(decodedData) as Array<{ role: string; content: string }>;
        
        // Convert to the internal format: [["AI","..."], ["Human","..."], ...]
        const finalHistory = arr.map(item => [item.role, item.content]);
        console.log("Final chat history:", finalHistory);
        setChatHistory(finalHistory);
      } catch (err) {
        console.error("Error parsing final chat JSON:", err);
      }
  
      // We’re done loading
      setIsLoading(false);
  
      // Optionally abort the SSE if final truly ends it
      if (streamingControllerRef.current) {
        streamingControllerRef.current.abort();
      }
    }
    // If there's no event name or some other event
    else {
      // By default, treat it as an "AI" update
      setChatHistory(prev =>
        prev ? [...prev, ["AI", decodedData]] : [["AI", decodedData]]
      );
    }
  };
  

  const handleHistoryError = (error: unknown) => {
    console.error("SSE error (history):", error);
    setMessage(`Error loading chat history: ${String(error)}`);
    setIsLoading(false);

    // Abort streaming on error
    if (streamingControllerRef.current) {
      streamingControllerRef.current.abort();
    }
  };

  const handleHistoryClose = () => {
    // The server closed the connection or we aborted
    console.log("Chat history SSE closed.");
    setIsLoading(false);
  };

  /**************************************************************
   * 3) UseEffect that calls loadChatHistory on component mount *
   **************************************************************/
  useEffect(() => {
    loadChatHistory();

    // On unmount, abort any active SSE
    return () => {
      if (streamingControllerRef.current) {
        streamingControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAction = async (action: string) => {
    handleChatSubmit(action);
  };

  const handleStrongifierSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!strongifierInput.trim()) return;

    const inputToSend = strongifierInput.trim();
    setStrongifierInput("");
    handleChatSubmit(inputToSend);
  };

  const handleClearChat = async () => {
    // Immediately clear local history and show loading indicator
    setChatHistory([]);
    setIsLoading(true);

    try {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await clearChatHistory(accessToken);

      if (error) {
        setMessage(`Error: ${error.message}`);
      } else {
        // After clearing the server history, load it again from the server
        await loadChatHistory();
      }
    } catch (error) {
      setMessage(
        `Error: ${
          error instanceof Error ? error.message : "Unknown error occurred"
        }`
      );
      setIsLoading(false);
    }
  };

  /**
   * Handle user-submitted message: start the streaming SSE connection.
   */
  const handleChatSubmit = async (human_message: string) => {
    // Add user's message to the local chat
    setChatHistory((prev) =>
      prev ? [...prev, ["Human", human_message]] : [["Human", human_message]]
    );
    setIsLoadingChatResponse(true);

    try {
      // Abort previous stream if it exists
      if (streamingControllerRef.current) {
        streamingControllerRef.current.abort();
      }

      const accessToken = await getAccessTokenSilently();

      // Start streaming with doChatStreaming
      const controller = doChatStreaming(
        accessToken,
        human_message,
        handleSSEMessage,
        handleSSEError,
        handleSSEClose
      );

      // Keep track of the current streaming controller
      streamingControllerRef.current = controller;
    } catch (error) {
      setMessage(
        `Error: ${
          error instanceof Error ? error.message : "Unknown error occurred"
        }`
      );
      setIsLoadingChatResponse(false);
    }
  };

  /**
   * Called on each SSE message. We'll parse the "event" and "data" to see
   * if it's an update or final chat event, then decode the base64 content.
   */
  const handleSSEMessage = (evt: EventSourceMessage) => {
    // The SSE library passes { event?: string; data: string; id?: string }
    const { event: evtName, data } = evt;

    // Decode base64 content
    const decodedContent = decodeBase64(data);

    // If your server sets `event: "update"` for partial chunks, add them as we go
    if (evtName === "update") {
      setChatHistory((prev) =>
        prev ? [...prev, ["AI", decodedContent]] : [["AI", decodedContent]]
      );
    }
    // If your server sets `event: "chat"` for the final chunk
    else if (evtName === "chat") {
      setChatHistory((prev) =>
        prev ? [...prev, ["AI", decodedContent]] : [["AI", decodedContent]]
      );
      setIsLoadingChatResponse(false);

      // If this is truly the final event, you can abort if you like
      if (streamingControllerRef.current) {
        streamingControllerRef.current.abort();
      }
    }
    // If no "event" name is set, you can decide how to handle the chunk
    else {
      // By default, treat it like an update
      setChatHistory((prev) =>
        prev ? [...prev, ["AI", decodedContent]] : [["AI", decodedContent]]
      );
    }
  };

  /**
   * Called if there's a network or server error in SSE.
   */
  const handleSSEError = (error: unknown) => {
    console.error("SSE error:", error);
    setIsLoadingChatResponse(false);

    // Also abort to clean up the connection
    if (streamingControllerRef.current) {
      streamingControllerRef.current.abort();
    }
  };

  /**
   * Called when the SSE connection closes (e.g., server finished streaming).
   */
  const handleSSEClose = () => {
    console.log("SSE stream closed by server or aborted.");
  };

  return (
    <PageLayout>
    <div className="flex flex-col items-center justify-between bg-gray-900 p-4 text-white grow text-2xl">
      <div className="flex flex-col items-center grow">
        {/* Waiting / no-chat Logo */}
        {!isLoading && (!chatHistory || chatHistory.length === 0) ? (
          <div className="mb-12 grow grid grid-cols-3 grid content-center">
            <svg
              className="w-16 h-16"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M50 10 L90 90 L10 90 Z" fill="white" />
            </svg>
          </div>
        ) : null}

        {/* Chat Response Area */}
        {isLoading ? (
          <div className="mb-12 grow grid grid-cols-3 grid content-center">
            <Loader className="w-12 h-12 text-blue-500 animate-spin" />
          </div>
        ) : (
          chatHistory && (
            <div className="mb-12 grow flex flex-col content-top p-6 space-y-4 overflow-y-auto">
              {chatHistory.map((entry, index) => {
                const [role, content] = entry;
                const isHuman = role === "Human";

                return (
                  <div
                    key={index}
                    className={`flex ${
                      isHuman ? "justify-end" : "justify-start"
                    }`}
                  >
                    <div
                      className={`max-w-3/4 ${
                        isHuman
                          ? "bg-blue-100 text-gray-800 rounded-l-lg rounded-br-lg ml-12"
                          : "bg-gray-800 text-white rounded-r-lg rounded-bl-lg mr-12"
                      } p-4 shadow-md`}
                    >
                      <div className="flex items-start space-x-2">
                        {!isHuman && (
                          <Bot className="w-6 h-6 text-blue-500 mt-1 flex-shrink-0" />
                        )}
                        <TextToHTML content={content} />
                        {isHuman && (
                          <User className="w-6 h-6 text-blue-500 mt-1 flex-shrink-0" />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

              {isLoadingChatResponse && (
                <div className="flex justify-start">
                  <div className="bg-gray-800 text-white rounded-r-lg rounded-bl-lg mr-12 p-4 shadow-md">
                    <div className="flex items-center space-x-2">
                      <Bot className="w-6 h-6 text-blue-500" />
                      <Loader className="w-6 h-6 text-blue-500 animate-spin" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        )}

          {/* Clear Chat Button */}
          {!isLoading && chatHistory && chatHistory.length > 0 ? (
            <button
              onClick={handleClearChat}
              className="mb-6 w-4/5 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center transition duration-300 ease-in-out"
            >
              <Trash2 className="mr-2" size={20} />
              Clear Chat
            </button>
          ) : null}

          {/* Action Buttons */}
          <div className="grid grid-cols-2 gap-4 mb-12 w-full">
            <ActionButton
              title="If I run a 29 minute 5k, how fast should I be able to run a half marathon in?"
              subtitle="Race Time Prediction"
              handleAction={handleAction}
            />
            <ActionButton
              title="Estimate My Threshold Speed and Heartrate over the past year"
              subtitle="Calculate Benchmark Run Stats"
              handleAction={handleAction}
            />
            <ActionButton
              title="Analyze my cardio zone distribution over the past month"
              subtitle="Understand Running History"
              handleAction={handleAction}
            />
            <ActionButton
              title="What should my next workout be?"
              subtitle="Optimize Training Plan"
              handleAction={handleAction}
            />
          </div>
        </div>

        {/* Message Strongifier */}
        <div className="w-full max-w-2xl mt-auto">
          <form onSubmit={handleStrongifierSubmit} className="flex items-center">
            <input
              type="text"
              value={strongifierInput}
              onChange={(e) => setStrongifierInput(e.target.value)}
              placeholder="Enter a message to Strongifier"
              className="flex-grow p-3 rounded-l-lg bg-gray-800 text-white border-2 border-r-0 border-gray-700 focus:outline-none focus:border-blue-500"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white p-3 rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};
