import { AxiosRequestConfig } from "axios";
import { callExternalApi } from "./external-api.service";
import { ChatApiResponse } from "../models/chat-api-response";
import { ChatHistoryApiResponse } from "../models/chat-history-response";
import { fetchEventSource, EventSourceMessage } from "@microsoft/fetch-event-source";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const doChat = async (
  accessToken: string,
  inputData: string
): Promise<ChatApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/chat`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: { "question": inputData }
  };

  const { data, error } = (await callExternalApi({ config })) as ChatApiResponse;


  return {
    data,
    error,
  };
};

//Clear Chat History POST service function.
export const clearChatHistory = async (
  accessToken: string
): Promise<ChatHistoryApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/chat/history/clear`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ChatHistoryApiResponse;

  return {
    data,
    error,
  };
};

export const getChatHistory = async (
  accessToken: string
): Promise<ChatHistoryApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/api/chat/history`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ChatHistoryApiResponse;




  return {
    data,
    error,
  };
};




/**
 * Connects to the chat streaming POST endpoint using fetch-event-source.
 * 
 * The `@microsoft/fetch-event-source` library lets us stream responses from a POST
 * request and handle SSE events in the browser. If your server sends multiple events
 * (like "update", "chat", etc.), you can parse them in `onMessage(evt)`.
 * 
 * @param accessToken Access token for authenticated users
 * @param question    The user’s input
 * @param onMessage   Callback fired on each SSE message event (partial or final).
 *                    Receives EventSourceMessage instead of a standard MessageEvent.
 * @param onError     Callback fired if there's an error
 * @param onClose     Callback fired when the stream ends or is aborted (optional)
 * @returns           An AbortController (use .abort() to cancel the stream)
 */
export const doChatStreaming = (
  accessToken: string,
  question: string,
  onMessage: (event: EventSourceMessage) => void,
  onError: (error: unknown) => void,
  onClose?: () => void
): AbortController => {
  // We'll use an AbortController to cancel the request if needed.
  const controller = new AbortController();

  fetchEventSource(`${apiServerUrl}/api/chat-stream`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ question }),
    signal: controller.signal,

    // Marked async to match the library's expected Promise<void> return type
    onopen: async (res: Response) => {
      if (res.ok) {
        // Connection has successfully opened and is ready to stream
      } else {
        // The server returned a non-OK status code (e.g., 401, 403)
        onError(`Error: HTTP ${res.status} ${res.statusText}`);
        controller.abort();
      }
    },

    // Note the signature uses EventSourceMessage
    onmessage(evt: EventSourceMessage) {
      // Each SSE event (partial or final) arrives here
      onMessage(evt);
    },

    onerror(err) {
      // A network or server error occurred
      onError(err);
      controller.abort();
    },

    onclose() {
      // The server closed the connection
      if (onClose) {
        onClose();
      }
    },
  });

  // Return the AbortController so that the caller can stop the stream if needed
  return controller;
};

/**
 * Connects to the chat history streaming POST endpoint (`api/chat-stream/hello`) using fetch-event-source.
 *
 * The backend sends SSE events in two possible ways:
 * 1) Partial or "update" events that contain incremental data about the user's chat history.
 * 2) A final SSE event that is a base64-encoded JSON array of shape: `[{"role":"Human","message":"hi"},...]`.
 *
 * @param accessToken Access token for authenticated users
 * @param onMessage   Callback for each SSE event (partial or final).
 *                    Receives an EventSourceMessage with .data (base64), .event, etc.
 * @param onError     Callback for errors
 * @param onClose     Callback for when the stream ends or is aborted
 * @returns           An AbortController (use .abort() to stop streaming)
 */
export const getChatHistoryStreaming = (
  accessToken: string,
  onMessage: (evt: EventSourceMessage) => void,
  onError: (error: unknown) => void,
  onClose?: () => void
): AbortController => {
  const controller = new AbortController();

  // We do a POST to `api/chat-stream/hello`. Adjust if your backend expects certain parameters.
  fetchEventSource(`${apiServerUrl}/api/chat-stream/hello`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    // If your server expects a body, you can provide one here. 
    // For now, let's assume we don’t need anything besides the token.
    body: JSON.stringify({}),
    signal: controller.signal,

    // Must be async to match the library's expected Promise<void> return type
    onopen: async (res: Response) => {
      if (res.ok) {
        // The SSE connection is open; the server will begin streaming chat history
      } else {
        onError(`Error: HTTP ${res.status} ${res.statusText}`);
        controller.abort();
      }
    },

    onmessage(evt: EventSourceMessage) {
      // Each chunk or final event arrives here. 
      // The final event might be a base64-encoded JSON array of chat items.
      onMessage(evt);
    },

    onerror(err) {
      onError(err);
      controller.abort();
    },

    onclose() {
      // The server closed the connection
      if (onClose) {
        onClose();
      }
    },
  });

  return controller;
};