// pages/profile-page.tsx

import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import { getProfile } from "../services/profile.service";
import { ProfileResponse } from "../models/profile-response";
import { Link } from "react-router-dom";

export const ProfilePage: React.FC = () => {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [profileData, setProfileData] = useState<ProfileResponse | null>(null);
  const [loadingProfile, setLoadingProfile] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getProfile(accessToken);

        if (response.error) {
          setError(response.error.message);
        } else {
          setProfileData(response.data);
        }
      } catch (e: any) {
        setError(e.message);
      } finally {
        setLoadingProfile(false);
      }
    };

    fetchProfile();
  }, [getAccessTokenSilently]);

  if (isLoading || loadingProfile) {
    return (
      <PageLayout>
        <div className="content-layout">
          <div className="content__body">
            <p className="text-center text-gray-600">Loading...</p>
          </div>
        </div>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div className="content-layout">
          <div className="content__body">
            <p className="text-center text-red-500">Error: {error}</p>
          </div>
        </div>
      </PageLayout>
    );
  }

  if (!profileData) {
    return null;
  }

  // Helper function to display values
  const displayValue = (value: any, unit?: string) => {
    if (
      value === null ||
      value === undefined ||
      value === "None" ||
      value === "null" ||
      value === "Unknown"
    ) {
      return "Unknown";
    }
    if (Array.isArray(value)) {
      return value.length > 0 ? value.join(", ") : "Unknown";
    }
    if (unit) {
      return `${value} ${unit}`;
    }
    return value;
  };

  const HRMIN = "HR_MIN";
  const HRMAX = "HR_MAX";

  return (
    <PageLayout>
      <div className="content-layout">
        <div className="content__body p-4">
          <div className="max-w-3xl mx-auto">

            {/* Explanatory Note */}
            <div className="mb-8">
              <p className="text-l text-gray-400">
                To change the profile information recorded here, just{" "}
                <Link to="/chat" className="underline">
                  ask your AI coach!
                </Link>
              </p>
            </div>

            {/* Personal Information */}
            <div className="mb-8">
              <h3 className="text-2xl font-bold mb-4 text-red-600">
                Personal Information
              </h3>
              <div className="bg-white shadow-md rounded-lg p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-gray-600 font-medium">
                    Preferred Name:
                  </div>
                  <div className="text-gray-800">
                    {displayValue(profileData.preferred_name)}
                  </div>
                  <div className="text-gray-600 font-medium">
                    Birthdate:
                  </div>
                  <div className="text-gray-800">
                    {displayValue(profileData.birthdate)}
                  </div>
                </div>
              </div>
            </div>

            {/* Goal Race Information */}
            <div className="mb-8">
              <h3 className="text-2xl font-bold mb-4 text-red-600">
                Goal Race Information
              </h3>
              <div className="bg-white shadow-md rounded-lg p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-gray-600 font-medium">Race Name:</div>
                  <div className="text-gray-800">
                    {displayValue(profileData.goal_race_name)}
                  </div>
                  <div className="text-gray-600 font-medium">Race Date:</div>
                  <div className="text-gray-800">
                    {displayValue(profileData.goal_race_date)}
                  </div>
                  <div className="text-gray-600 font-medium">
                    Race Distance:
                  </div>
                  <div className="text-gray-800">
                    {displayValue(profileData.goal_race_distance, "km")}
                  </div>
                </div>
              </div>
            </div>

            {/* Thresholds */}
            <div className="mb-8">
              <h3 className="text-2xl font-bold mb-4 text-red-600">
                Thresholds
              </h3>
              <div className="bg-white shadow-md rounded-lg p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-gray-600 font-medium">
                    Threshold Heart Rate:
                  </div>
                  <div className="text-gray-800">
                    {displayValue(profileData.threshold_heart_rate, "bpm")}
                  </div>
                  <div className="text-gray-600 font-medium">
                    Threshold Pace:
                  </div>
                  <div className="text-gray-800">
                    {displayValue(profileData.threshold_pace, "min/km")}
                  </div>
                </div>
              </div>
            </div>

            {/* Heart Rate Zones */}
            <div className="mb-8">
              <h3 className="text-2xl font-bold mb-4 text-red-600">
                Heart Rate Zones
              </h3>
              <div className="bg-white shadow-md rounded-lg p-6">
                {profileData.heart_rate_zones &&
                profileData.heart_rate_zones.length > 0 ? (
                  (() => {
                    const heartRateZones = profileData.heart_rate_zones as number[];
                    return (
                      <div className="grid grid-cols-2 gap-4">
                        {heartRateZones.map((upperBoundary, index) => {
                          let min, max;
                          let zoneName = `Zone ${index + 1}`;

                          if (index === 0) {
                            // First zone
                            min = HRMIN;
                            max = upperBoundary;
                          } else {
                            min = heartRateZones[index - 1];
                            max = upperBoundary;
                          }

                          // Render the zone
                          return (
                            <React.Fragment key={zoneName}>
                              <div className="text-gray-600 font-medium">{zoneName}:</div>
                              <div className="text-gray-800">
                                {displayValue(min)} - {displayValue(max)} bpm
                              </div>
                            </React.Fragment>
                          );
                        })}

                        {/* Add the final zone */}
                        <div className="text-gray-600 font-medium">
                          {`Zone ${heartRateZones.length + 1}`}:
                        </div>
                        <div className="text-gray-800">
                          {displayValue(
                            heartRateZones[heartRateZones.length - 1]
                          )}{" "}
                          - {displayValue(HRMAX)} bpm
                        </div>
                      </div>
                    );
                  })()
                ) : (
                  <p className="text-gray-800">Unknown</p>
                )}
              </div>
            </div>

            {/* Training Preferences */}
            <div className="mb-8">
              <h3 className="text-2xl font-bold mb-4 text-red-600">
                Training Preferences
              </h3>
              <div className="bg-white shadow-md rounded-lg p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="text-gray-600 font-medium">
                    Maximum Weekly Training Duration:
                  </div>
                  <div className="text-gray-800">
                    {displayValue(profileData.maximum_weekly_training_duration, "hours")}
                  </div>
                  <div className="text-gray-600 font-medium">
                    Long Run Days:
                  </div>
                  <div className="text-gray-800">
                    {displayValue(profileData.long_run_days)}
                  </div>
                  <div className="text-gray-600 font-medium">
                    Number of Workouts Per Week:
                  </div>
                  <div className="text-gray-800">
                    {displayValue(profileData.number_of_workouts_per_week)}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </PageLayout>
  );
};

